
.ant-table-row.PLAYTIME_RED td:nth-child(2) {
  background-color: red !important;
  color: white;
}

.ant-table-row.PLAYTIME_RED:hover td:nth-child(2) {
  color: #4a505c;
}

.ant-table-row.PLAYTIME_ORANGE td:nth-child(2) {
  background-color: orange !important;
}

.ant-table-row.PLAYTIME_YELLOW td:nth-child(2) {
  background-color: yellow !important;
}

.ant-table-row.FINISHED td:nth-child(2), .ant-table-row.FINISHED:hover td:nth-child(2) {
  background-color: lawngreen;
}

.ant-table-row.PLAYTIME_ORANGE td:nth-child(2) button,
.ant-table-row.PLAYTIME_YELLOW td:nth-child(2) button,
.ant-table-row.PLAYTIME_RED td:nth-child(2) button {
  color: white;
}

