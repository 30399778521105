@import '../../assets/sass/variables';

.horizontal {
  .app-container {

    .navbar.horizontal {
      > * {
        margin: 0 $module-rem;
      }
      .navbar-skeleton {
        .sk-logo {
          display: none !important;
        }
      }
    }
    .horizontal-navbar {
      box-shadow: 0 0 10px 0 rgba(#000, 0.15);

      .logo {
        padding-left: 9px;
      }
    }
    .top-bar {
      z-index: 998;
    }
    .menu-bar {
      background: linear-gradient(188deg, rgb(5, 105, 202), rgb(0, 17, 172) 65%);
      color: rgb(255, 255, 255);
      min-height: 50px;
    }
  }
}
