#mngCalendarItemsTable td:nth-child(8) {
  text-align: right;
}

#updateWebsiteContent .poster-uploader > .ant-upload {
  width: 100%;
  height: auto;
  text-align: center;
}

#updateWebsiteContent .poster-uploader > .ant-upload .remove-logo-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

#updateWebsiteContent .poster-uploader > .ant-upload img {
  width: auto;
  max-width: 100%;
}