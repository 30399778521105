@import '../../../../assets/sass/variables';

$_circle-size: 2 * $module;
$_animation: cubic-bezier(0.7, 0, 0.3, 1);

.color-field {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 38px;
  position: relative;
  min-width: 90px;

  &:before {
    border-bottom: 1px solid rgba(#000, 0.4);
    bottom: 0;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 1px;
  }
  .circle {
    background: #fff;
    border: 1px solid rgba(#000, 0.05);
    border-radius: 50%;
    display: block;
    height: $_circle-size;
    position: absolute;
    overflow: hidden;
    transition: border-radius 0.2s $_animation, height 0.2s $_animation, left 0.2s $_animation,
      transform 0.2s $_animation, width 0.2s $_animation;
    width: $_circle-size;
    will-change: border-radius, height, left, transform, width;
    z-index: 2;

    .bg {
      display: block;
      left: 0;
      padding-top: 100%;
      position: absolute;
      right: 0;
      top: 9px;
      transform: translate(0, -50%);
      transition: transform 0.4s $_animation;

      .white {
        border-radius: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: border-radius 0.2s $_animation;
        width: 100%;
        will-change: border-radius;
      }
      .transparent {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJUlEQVQYV2O8devWfwY0oKamxoguxjgUFKI7GsTH5m4M3w1ChQBEjCXMAbX0MQAAAABJRU5ErkJggg==)
          repeat left top;
        background-repeat: repeat;
        background-position: 0 0;
        background-size: 12px;
        border-radius: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: border-radius 0.2s cubic-bezier(0.7, 0, 0.3, 1);
        width: 100%;
        will-change: border-radius;
      }
      .bg-color {
        border-radius: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: border-radius 0.2s cubic-bezier(0.7, 0, 0.3, 1);
        width: 100%;
        will-change: border-radius;
      }
    }
    .cursor {
      border-radius: 50% !important;
      border: 1px solid #fff;
      display: block;
      height: 9px;
      left: 50%;
      margin: -4.5px 0 0 -4.5px;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: transform 0.2s $_animation, opacity 0.2s $_animation;
      width: 9px;
      z-index: 3;

      &:after {
        background: #fff;
        content: '';
        display: block;
        height: 14px;
        position: absolute;
        margin: -3px 0 0 3px;
        width: 1px;
      }
      &:before {
        background: #fff;
        content: '';
        display: block;
        height: 1px;
        margin: 3px 0 0 -3px;
        position: absolute;
        width: 14px;
      }
      &.black {
        border-color: #000;

        &:before,
        &:after {
          background: #000;
        }
      }
    }
    &:hover {
      .cursor {
        opacity: 1;
        transform: scale(1);
        transition: transform 0.2s $_animation 0.1s, opacity 0.1s $_animation;
      }
    }
  }
  .selected-color-value {
    margin-left: auto;
  }

  &:hover {
    .circle {
      border-radius: 0;
      height: 100%;
      transform: translate(0, 0);
      width: calc(100% + 1px);

      :not(.cursor) {
        border-radius: 0 !important;
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.gradient {
    min-width: 16.5 * $module;

    .circle {
      &:not(:first-child) {
        left: 12px;
      }
    }

    &:hover {
      .circle {
        width: 50%;

        &:not(:first-child) {
          left: 50%;
        }
      }
    }

    :first-child {
      z-index: 2;
    }
  }
}
