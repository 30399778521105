#root {
  height: 100%;

  .App {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
  }
}
