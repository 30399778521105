#uploadNewsAttachmentForm .attachment-uploader > .ant-upload {
  width: 100%;
  height: auto;
  text-align: center;
}

#uploadNewsAttachmentForm .attachment-uploader > .ant-upload .remove-logo-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

#uploadNewsAttachmentForm .attachment-uploader > .ant-upload img {
  width: auto;
  max-width: 100%;
}