.table-button-top-left {
  position: absolute;
  top: 48px;
  left: 5px;
  font-size: 24px;
}

.table-button-top-right {
  position: absolute;
  top: 48px;
  right: 5px;
  font-size: 24px;
}

.table-button-top-right.second {
  top: 80px;
}

.shotClockInfo.blinking {
  animation: blinker 5s linear infinite;
}

#overviews-tables .ant-card .PLAYTIME_ORANGE {
  animation: blinker 5s linear infinite;
}

#overviews-tables .ant-card .PLAYTIME_RED {
  animation: blinker 1s linear infinite;
}

#overviews-tables div.ant-typography, .ant-typography p {
  margin-bottom: 0.5rem;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}