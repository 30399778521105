#uploadEventPoster .poster-uploader > .ant-upload {
  width: 100%;
  height: auto;
  text-align: center;
}

#uploadEventPoster .poster-uploader > .ant-upload .remove-poster-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

#uploadEventPoster .poster-uploader > .ant-upload img {
  width: auto;
  max-width: 100%;
}